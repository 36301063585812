import React from 'react';
import { Row, Col, Grid, Button } from 'reaxl';
import { atcBaseUrl, kbbAndAtcLogoImgUrl } from '../../constants/config';
import { sendClick } from 'reaxl-analytics';
import Image from 'next/image';
import { akamaiLoader } from '../../../utils';

const sellMyCarUrl = `${atcBaseUrl}/sell-my-car`;
const sellYourCarText = 'Sell Your Car';
const verifiedBuyersText = 'Chat directly with verified buyers, schedule test drives, and sell online from the comfort of your home.';
const sellMyCarButtonText = 'Sell My Car';
export default function BuyerLandingSellCar() {
  const onSellMyCarLabelClick = (event: any) => {
    sendClick('psxSellMyCarClick', event);
  };
  return (
    <div className="sell-your-car-section">
      <Grid>
        <Row className="gutter-16">
          <Col
            xs={12}
            md={10}
            mdOffset={1}
            lg={8}
            lgOffset={2}
          >
            <p className="sell-your-car-heading">{sellYourCarText}</p>
            <Row>
              <Col
                xs={12}
                sm={6}
              >
                <p>{verifiedBuyersText}</p>
              </Col>
              <Col
                xs={12}
                sm={6}
              >
                <div className="sell-cta">
                  <Button
                    bsStyle="primary"
                    href={sellMyCarUrl}
                    onClick={(event: any) => onSellMyCarLabelClick(event)}
                  >
                    {sellMyCarButtonText}
                  </Button>
                  <div>
                    <Image
                      loader={akamaiLoader}
                      src={kbbAndAtcLogoImgUrl}
                      alt="Sell My Car"
                      width={298}
                      height={56}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
