import React, { useState } from 'react';
import { ListColumns, Link, Text, Accordion, AccordionPanel } from 'reaxl';
import {
  statesNames,
  stateAbbreviations,
  carsCities,
  trucksCities,
  privateSellerCarsLinks,
} from '../../constants/myArea';
import { atcBaseUrl } from '../../constants/config';
import { sendClick } from 'reaxl-analytics';

export default function MyAreaSection() {
  const onStateClick = (event: any, state: string) => {
    sendClick('howToSellACarInStateClick', event, { state });
  };
  const onPrivateSellerCarsInMyAreaClick = (event: any) => {
    sendClick('privateSellerCarsInMyArea', event);
  };
  const onPrivateSellerTrucksInMyAreaClick = (event: any) => {
    sendClick('privateSellerTrucksInMyArea', event);
  };
  const onPrivateSellerCarsClick = (event: any) => {
    sendClick('privateSellerCars', event);
  };
  const onSellMyCarsClick = (event: any) => {
    sendClick('sellMyCars', event);
  };
  const onPreQualifyClick = (event: any) => {
    sendClick('preQualify', event);
  };
  const onAutoGAPInsuranceClick = (event: any) => {
    sendClick('autoGAPInsurance', event);
  };
  const onVehicleProtectionClick = (event: any) => {
    sendClick('vehicleProtection', event);
  };
  const onFAQsClick = (event: any) => {
    sendClick('FAQs', event);
  };
  const onBuyerGuideClick = (event: any) => {
    sendClick('buyerGuide', event);
  };
  const onSellerGuideClick = (event: any) => {
    sendClick('sellerGuide', event);
  };
  const extrasCol0 = [
    <div
      className="linkDiv"
      key="a"
    >
      <Link
        href={`${atcBaseUrl}/cars-for-sale/by-owner`}
        label="Private Seller Cars"
        onClick={(event: any) => onPrivateSellerCarsClick(event)}
      />
    </div>,
    <div
      className="linkDiv"
      key="a"
    >
      <Link
        href={`${atcBaseUrl}/sell-my-car`}
        label="Sell My Cars"
        onClick={(event: any) => onSellMyCarsClick(event)}
      />
    </div>,
    <div
      className="linkDiv"
      key="a"
    >
      <Link
        href={`${atcBaseUrl}/marketplace/financing`}
        label="Pre Qualify"
        onClick={(event: any) => onPreQualifyClick(event)}
      />
    </div>,
    <div
      className="linkDiv"
      key="a"
    >
      <Link
        href={`${atcBaseUrl}/marketplace/gap-insurance`}
        label="Auto GAP Insurance"
        onClick={(event: any) => onAutoGAPInsuranceClick(event)}
      />
    </div>,
    <div
      className="linkDiv"
      key="a"
    >
      <Link
        href={`${atcBaseUrl}/marketplace/vehicle-service-contracts`}
        label="Vehicle Protection"
        onClick={(event: any) => onVehicleProtectionClick(event)}
      />
    </div>,
  ];

  const extrasCol1 = [
    <div
      className="linkDiv"
      key="a"
    >
      <Link
        href={`${atcBaseUrl}/marketplace/faqs`}
        label="FAQs"
        onClick={(event: any) => onFAQsClick(event)}
      />
    </div>,
    <div
      className="linkDiv"
      key="a"
    >
      <Link
        href={`${atcBaseUrl}/marketplace/buyer-guide`}
        label="Buyer Guide"
        onClick={(event: any) => onBuyerGuideClick(event)}
      />
    </div>,
    <div
      className="linkDiv"
      key="a"
    >
      <Link
        href={`${atcBaseUrl}/marketplace/seller-guide`}
        label="Seller Guide"
        onClick={(event: any) => onSellerGuideClick(event)}
      />
    </div>,
  ];
  const [extrasItemsCol0] = useState<any[]>(extrasCol0); // Popular Services and Tips [extras]
  const [extrasItemsCol1] = useState<any[]>(extrasCol1);
  const generateStateComponents = () => {
    const stateComponents: any[] = [];
    for (let i = 0; i < 51; i++) {
      const state = (
        <div
          className="linkDiv"
          key="a"
        >
          <Link
            href={
              atcBaseUrl + '/sell-my-car/in-' + stateAbbreviations[i]
            }
            label={statesNames[i]}
            onClick={(event: any) => onStateClick(event, stateAbbreviations[i])}
          />
        </div>
      );
      stateComponents.push(state);
    }
    return stateComponents;
  };
  const generateCarsCityComponents = () => {
    const carsCityComponents: any[] = [];
    for (let i = 0; i < carsCities.length; i++) {
      const privateSellerCarsCity = (
        <div
          className="linkDiv"
          key="a"
        >
          <Link
            href={
              atcBaseUrl + '/cars-for-sale/by-owner/'
              + carsCities[i].path
            }
            label={'Cars for Sale by Owner in ' + carsCities[i].name}
            onClick={(event: any) => onPrivateSellerCarsInMyAreaClick(event)}
          />
        </div>
      );
      carsCityComponents.push(privateSellerCarsCity);
    }
    return carsCityComponents;
  };
  const generateTrucksCityComponents = () => {
    const trucksCityComponents: any[] = [];
    for (let i = 0; i < trucksCities.length; i++) {
      const privateSellerTrucksCity = (
        <div
          className="linkDiv"
          key="a"
        >
          <Link
            href={
              atcBaseUrl + '/cars-for-sale/by-owner/truck/'
              + trucksCities[i].path
            }
            label={'Trucks for Sale by Owner in ' + trucksCities[i].name}
            onClick={(event: any) => onPrivateSellerTrucksInMyAreaClick(event)}
          />
        </div>
      );
      trucksCityComponents.push(privateSellerTrucksCity);
    }
    return trucksCityComponents;
  };
  const generatePrivateSellerCarsComponents = () => {
    const privateSellerCarsComponents: any[] = [];
    for (let i = 0; i < privateSellerCarsLinks.length; i++) {
      const privateSellerCarsLink = (
        <div
          className="linkDiv"
          key="a"
        >
          <Link
            href={
              atcBaseUrl + privateSellerCarsLinks[i].path
            }
            label={privateSellerCarsLinks[i].name}
          />
        </div>
      );
      privateSellerCarsComponents.push(privateSellerCarsLink);
    }
    return privateSellerCarsComponents;
  };
  return (
    <div className="my-area-section">
      <Accordion
        bsStyle="filters"
        defaultActiveKey="panel4"
        // onClose={function noRefCheck() {}}
        // onOpen={function noRefCheck() {}}
        // onToggle={function noRefCheck() {}}
      >
        <AccordionPanel
          eventKey="panel1"
          title={(
            <Text
              componentClass="h3"
              weight="bold"
              size="500"
              className="textStyle"
            >
              Private Seller Cars in My Area
            </Text>
          )}
        >
          <ListColumns
            className="listStyle"
            columns="4"
            items={generateCarsCityComponents()}
            lg={4}
            md={4}
            sm={1}
            xs={1}
          />
        </AccordionPanel>
        <AccordionPanel
          eventKey="panel2"
          title={(
            <Text
              componentClass="h3"
              weight="bold"
              size="500"
              className="textStyle"
            >
              Private Seller Trucks in My Area
            </Text>
          )}
        >
          <ListColumns
            className="listStyle"
            items={generateTrucksCityComponents()}
            lg={4}
            md={4}
            sm={1}
            xs={1}
          />
        </AccordionPanel>
        <AccordionPanel
          eventKey="panel5"
          title={(
            <Text
              componentClass="h3"
              weight="bold"
              size="500"
              className="textStyle"
            >
              Private Seller Cars by Price
            </Text>
          )}
        >
          <ListColumns
            className="listStyle"
            items={generatePrivateSellerCarsComponents()}
            lg={4}
            md={4}
            sm={1}
            xs={1}
          />
        </AccordionPanel>
        <AccordionPanel
          eventKey="panel3"
          title={(
            <Text
              componentClass="h3"
              weight="bold"
              size="500"
              className="textStyle"
            >
              How to Sell a Car in...
            </Text>
          )}
        >
          <ListColumns
            className="listStyle"
            items={generateStateComponents()}
            lg={4}
            md={4}
            sm={1}
            xs={1}
          />
        </AccordionPanel>
        <AccordionPanel
          className="twoListColumns"
          eventKey="panel4"
          title={(
            <Text
              componentClass="h3"
              weight="bold"
              size="500"
              className="textStyle"
            >
              Popular Services and Tips
            </Text>
          )}
        >
          <ListColumns
            className="listStyle"
            items={extrasItemsCol0}
            lg={1}
            md={1}
            sm={1}
            xs={1}
          />
          <ListColumns
            className="listStyle"
            items={extrasItemsCol1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
          />
        </AccordionPanel>
      </Accordion>
    </div>
  );
}
