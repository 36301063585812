import React, { useState, Suspense, memo, useCallback } from 'react';
import { Header } from 'reaxl-header';
import { addBaseUrlToNavigation } from 'atc-js';
import { useSelector } from 'react-redux';
import { redirectExternal } from '../utilities';
import { sendClick, sendImpressions } from 'reaxl-analytics';
import { ErrorBoundary } from 'reaxl-molecules';
import dynamic from 'next/dynamic';
import {
  headerBadge,
  header,
} from 'axl-config';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { connect } from 'react-redux';
import {
  userDuck,
  authDuck,
  savedInventoryDuck,
  paymentsDuck,
  recentSearchDuck,
} from '../ducks';

const AuthenticationHeader = dynamic(
  () => import('reaxl-organisms/es/AuthenticationHeader'),
  { ssr: false },
);
const headerBadgeNavItems = Object.keys(headerBadge).reduce((acc, itemKey) => ({
  ...acc,
  [itemKey]: headerBadge[itemKey],
}), {});
const AtcHeader = memo(({
  onLoggedInStateReady = () => { },
  showHeaderContent = true,
  showEmailSubscribe = false,
  showUserState = true,
  onClick = () => { },
  onSignInClick = () => { },
  onLogInSuccess = () => { },
  onSignOutClick = () => { },
  onEmailSubscribe = () => { },
  psxNotificationsCount = 0,
  ...rest
}) => {
  const loggedInVal = useSelector(authDuck.selectors.isLoggedIn);
  const isLoggedIn = useSelector((state) => {
    let loggedIn = typeof window !== 'undefined' ? loggedInVal : false;
    if (loggedInVal === undefined) {
      loggedIn = false;
    }
    return loggedIn;
  });
  const [littleGuyLoggedIn, setLittleGuyLoggedIn] = useState(isLoggedIn);
  const handleQuickSaveItemClick = (event, listing) => {
    redirectExternal('/cars-for-sale/vehicledetails.xhtml?listingId=' + listing.id);
  };
  const handleQuickSaveItemToggle = (event, inventory = {}, wasSaved = false) => {
    sendClick('inventorySave', event, { inventoryId: inventory.id, wasSaved });
  };
  const handleSaveInventoryUpdate = (data = {}) => {
    const {
      listing,
      alertByText,
      alertByEmail,
    } = data;
    if (alertByText) {
      sendClick('carAlertsClick', undefined, { eventResultText: 'checkbox::alert::text-car-vin', vehicle: listing });
    }
    if (alertByEmail) {
      sendClick('carAlertsClick', undefined, { eventResultText: 'checkbox::alert::email-car-vin', vehicle: listing });
    }
  };
  const handleClick = (event, data) => {
    sendClick('headerGeneralEventHandler', event, { ...data });
  };
  const onSponsorContentReady = (e, data) => {
    sendImpressions({
      name: 'headerGeneralEventHandler',
      data: { ...data, linkContext: 'PartnersImpression' },
    });
  };
  const handleOnSignOutClick = () => {
    onSignOutClick();
    setLittleGuyLoggedIn(false);
  };
  const renderHeaderContent = useCallback(() => (
    <Suspense fallback={null}>
      <ErrorBoundary>
        <AuthenticationHeader
          navItems={headerBadgeNavItems}
          userDuck={userDuck}
          authDuck={authDuck}
          recentSearchDuck={recentSearchDuck}
          savedInventoryDuck={savedInventoryDuck}
          paymentsDuck={paymentsDuck}
          showEmailSubscribe={showEmailSubscribe}
          onEmailSubscribe={onEmailSubscribe}
          onQuickSaveItemClick={handleQuickSaveItemClick}
          onQuickSaveItemToggle={handleQuickSaveItemToggle}
          onSaveInventoryUpdate={handleSaveInventoryUpdate}
          isLoggedIn={littleGuyLoggedIn}
          onLoggedInStateReady={onLoggedInStateReady}
          onClick={handleClick}
          onSignInClick={onSignInClick}
          onSignOutClick={handleOnSignOutClick}
          showUserState={showUserState}
          psxNotificationsCount={psxNotificationsCount}
          {...rest}
        />
      </ErrorBoundary>
    </Suspense>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    authDuck,
    userDuck,
    paymentsDuck,
    headerBadgeNavItems,
    onLoggedInStateReady,
    onLogInSuccess,
    handleQuickSaveItemToggle,
    handleSaveInventoryUpdate,
    onEmailSubscribe,
    showUserState,
    psxNotificationsCount,
  ]);
  return showHeaderContent && (
    <Header
      navItems={addBaseUrlToNavigation(header.navItems, false)}
      headerContent={renderHeaderContent()}
      onClick={handleClick}
      onSponsorContentReady={onSponsorContentReady}
    />
  );
});
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = mapDispatchToActionProp({
  hydrateUserInventory: userDuck.creators.hydrateUserInventory,
  hydrateRecentInventory: userDuck.creators.hydrateRecentInventory,
  hydrateRecentSearches: recentSearchDuck.creators.hydrateRecentSearches,
});
export default connect(mapStateToProps, mapDispatchToProps)(AtcHeader);
